import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, Container, NavDropdown, Offcanvas } from 'react-bootstrap';
import { Link } from 'gatsby-plugin-react-i18next';
import ReactModal from 'react-modal';
import styled from 'styled-components';

import LogoImage from '@images/logo-horizontal.svg';
import AddImage from '../../images/add.svg';
import CartImage from '../../images/cart.svg';
import FacebookIcon from '@images/icon-facebook.svg';
import InstagramIcon from '@images/icon-instagram.svg';
import YoutubeIcon from '@images/icon-youtube.svg';

// Icons
import CubacelRechargeIcon from '../../images/recharge-cubacel.svg';
import NautaRechargeIcon from '../../images/recharge-nauta.svg';
import CallsRechargeIcon from '../../images/recharge-calls.svg';
import SmsRechargeIcon from '../../images/recharge-sms.svg';
import MessageIcon from '../../images/icon-message.svg';
import ProfileIcon from '../../images/icon-profile.svg';
import InicioIcon from '../../images/icon-inicio.svg';
import ContactsIcon from '../../images/icon-contacts.svg';
import HistoryIcon from '../../images/icon-history.svg';
import PaymentIcon from '../../images/icon-payment.svg';
import PasswordIcon from '../../images/icon-password.svg';
import InfoIcon from '../../images/icon-info.svg';
import HelpIcon from '../../images/icon-help.svg';
import LogoutIcon from '../../images/icon-logout.svg';
import LoginIcon from '../../images/icon-login.svg';
import CallIcon from '../../images/icon-call.svg';
import AppStore from '../../images/appstore.svg';
import GooglePlay from '../../images/google-play.svg';

const StoreButton = styled.svg`
  @media (max-width: 767px) {
    width: 120px;
  }
`;
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-40%',
    transform: 'translate(-50%, -50%)',
  },
};

const StyledContainer = styled(Container)`
  // border-bottom: 1px solid ${(props) => props.bordercolor};
  padding-bottom: 0.6rem;
`;

const Logo = styled(LogoImage)`
  height: 30px;
`;

const AddIcon = styled(AddImage)`
  height: 27px;
  float: right;
  margin-left: 5px;
  fill: #1c7cd5;
`;

const CartIcon = styled(CartImage)`
  height: 27px;
  fill: #1c7cd5;
`;

const CartLabel = styled.div`
  position: absolute;
  top: 5px;
  left: 33px;
  height: 27px;
  width: 14px;
  text-align: center;
  font-size: 80%;
`;

const TextMenu = styled.div`
  color:  #1c7cd5;
`;

const SocialMediaBtn = styled.svg`
  height: 36px;
  width: 36px;
`;

const Header = (props) => {
  const { 
    fixedHeaderBg = false,
    disableLogin = false,
    landingOnly = false,
    NavigationOptions,
  } = props;

  const [userHasScrolled, setUserHasScrolled] = useState(false);
  const [isLoggedIn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleScrollState = () => {
    if (window.scrollY > 10) {
      setUserHasScrolled(true);
    } else {
      setUserHasScrolled(false);
    }
  };

  // const onToggleNavbar = (isVisible) => {
  //   document.body.classList.toggle("no-scroll", isVisible)
  // }

  useEffect(() => {
    document.addEventListener('scroll', toggleScrollState);
    return () => {
      document.removeEventListener('scroll', toggleScrollState);
    };
  }, []);

  const loginButton = (
    <a href="/es/account/login" className="nav-link">
      Iniciar sesión
    </a>
  );

  const accountOptions = (
    <>
      <Nav.Link href="#" className="d-flex align-items-center">
        <span>Saldo $15.00</span>
        <AddIcon />
      </Nav.Link>

      <NavDropdown
        title="UserID"
        id="user-options"
        className="push-dropdown-menu-right"
      >
        <NavDropdown.Item href="#">Perfil de usuario</NavDropdown.Item>
        <NavDropdown.Item className="text-danger">
          Cerrar sesión
        </NavDropdown.Item>
      </NavDropdown>

      <Nav.Link href="#">
        <CartIcon />
        <CartLabel>0</CartLabel>
      </Nav.Link>
    </>
  );

  const openModalDownloadApp = () => {
    setIsModalOpen(true);
    handleClose();
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => setMenuOpen(false);

  const renderAccountOptions = () => {
    if (disableLogin) return null;
    return isLoggedIn ? accountOptions : loginButton;
  };

  return (
    <>
      <Navbar
        expand="lg"
        variant="light"
        bg={fixedHeaderBg || userHasScrolled ? 'white' : null}
        fixed="top"
        className="pb-0"
      >
        <StyledContainer
          bordercolor={fixedHeaderBg || userHasScrolled ? 'transparent' : '#6c757d20'}
        >
          <Navbar.Brand className="mx-auto">
            <Link to="/" className="nav-link">
              <Logo/>
            </Link>
          </Navbar.Brand>

          {/* ------ MENU ----- */}
          {!disableLogin && (
            <Nav className="d-none d-lg-flex align-items-center">
              {renderAccountOptions()}
            </Nav>
          )}

          {!!NavigationOptions && (
            <Nav className="align-items-center">
              {NavigationOptions}
            </Nav>
          )}
          {/* ------ MENU END ----- */}

          {/* ----- SIDEBAR MENU ----- */}
          {!NavigationOptions && (
            <Navbar.Toggle
              aria-controls="offcanvasNavbar"
              onClick={toggleMenu}
            />
          )}
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            restoreFocus={false}
            show={menuOpen}
            onHide={handleClose}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                <Logo />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>

              {!landingOnly && (
                <Nav className="main-menu mb-3">
                  <Nav.Link href="/es/index">
                    <InicioIcon />
                    <TextMenu> Inicio </TextMenu>
                  </Nav.Link>
                  <Nav.Link href="/es/account/profile">
                    <ProfileIcon />
                    <TextMenu>Ver Perfil </TextMenu>
                  </Nav.Link>

                  {/* <Nav.Link href="#">
                  <ContactsIcon />
                  Contactos
                </Nav.Link> */}

                  <Nav.Link href="/es/account/history">
                    <HistoryIcon />
                    <TextMenu>Historial </TextMenu>
                  </Nav.Link>

                  {/* <Nav.Link href="#">
                  <PaymentIcon />
                  Métodos de pago
                </Nav.Link> */}

                  <Nav.Link href="/es/account/pin/change">
                    <PasswordIcon />
                    <TextMenu>Cambiar contraseña</TextMenu>
                  </Nav.Link>
                </Nav>
              )}

              {!landingOnly && (
                <Nav className="main-menu mb-3">
                  <Nav.Link href="/es/menu?prepaid=3">
                    <CubacelRechargeIcon />
                    <TextMenu>Recargar Cubacel</TextMenu>
                  </Nav.Link>
                  <Nav.Link href="/es/menu?prepaid=4">
                    <NautaRechargeIcon />
                    <TextMenu>Recargar Nauta</TextMenu>
                  </Nav.Link>
                  <Nav.Link href="/es/menu?prepaid=1">
                    <CallsRechargeIcon />
                    <TextMenu>Recargar llamadas</TextMenu>
                  </Nav.Link>
                  <Nav.Link href="/es/sms/send">
                    <MessageIcon />
                    <TextMenu>Enviar SMS</TextMenu>
                  </Nav.Link>
                  <Link to="#" onClick={openModalDownloadApp}>
                    <CallIcon />
                    <TextMenu>Llamar</TextMenu>
                  </Link>
                </Nav>
              )}

              {/* {landingOnly && (
                <Nav className="main-menu mb-3">
                  <Link to="/cubacel_recharge/" className="nav-link">
                    <CubacelRechargeIcon /> Recargar Cubacel
                  </Link>

                  <Link to="/nauta_recharge/" className="nav-link">
                    <NautaRechargeIcon /> Recargar Nauta
                  </Link>

                  <Link to="/calls/" className="nav-link">
                    <CallsRechargeIcon /> Llamadas
                  </Link>
                </Nav>
              )} */}

              <Nav className="main-menu mb-3">
                <Link to="/terms_and_conditions/" className="nav-link">
                  <InfoIcon />
                  <TextMenu>Términos y condiciones</TextMenu>
                </Link>
                <Link to="/privacy_notice/" className="nav-link">
                  <InfoIcon />
                  <TextMenu>Políticas de privacidad</TextMenu>
                </Link>
                <Link to="/return_and_cancelation/" className="nav-link">
                  <InfoIcon />
                  <TextMenu>Devolución y cancelación</TextMenu>
                </Link>
                {/* <Link to="/contact_us/" className="nav-link">
                  <HelpIcon />
                  <TextMenu>Ayuda y soporte</TextMenu>
                </Link> */}
                
                {!landingOnly && isLoggedIn && (
                  <Nav.Link href="/es/account/logout">
                    <LogoutIcon />
                    <TextMenu>Cerrar sesión</TextMenu>
                  </Nav.Link>
                )}
                {!landingOnly && !isLoggedIn && (
                  <Nav.Link href="/es/account/login">
                    <LoginIcon />
                    <TextMenu>Iniciar sesión</TextMenu>
                  </Nav.Link>
                )}
              </Nav>

              <div className="mt-4">
                <span className="text-muted small ms-2">
                      Síguenos en redes sociales
                </span>
                <div className="mt-2">
                  <a
                    href="https://www.facebook.com/islaceloficial"
                    target="_blank"
                    rel="noreferrer"
                    className="ms-2"
                  >
                    <SocialMediaBtn as={FacebookIcon} />
                  </a>

                  <a
                    href="https://instagram.com/islaceloficial"
                    target="_blank"
                    rel="noreferrer"
                    className="ms-2"
                  >
                    <SocialMediaBtn as={InstagramIcon} />
                  </a>

                  <a
                    href="https://www.youtube.com/channel/UCTY_Yis-aqfDckRJvn5pMXw"
                    target="_blank"
                    rel="noreferrer"
                    className="ms-2"
                  >
                    <SocialMediaBtn as={YoutubeIcon} />
                  </a>
                </div>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          {/* ----- SIDEBAR MENU END ----- */}
        </StyledContainer>
      </Navbar>
      
      
      {isModalOpen && (
        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          style={customStyles}
          contentLabel={'¿Deseas llamar a Cuba?'}
        >
          <div className="text-center">
            <h1 className="mb-3 mb-md-4 border-bottom">
              ¿Deseas llamar a Cuba?
            </h1>
            <p className="lead mx-auto">
              Para realizar llamadas a Cuba debes utilizar nuestra app de
              Islacel. <br className="d-none d-md-block" />
              Disponible gratis en.
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <a
                href="https://apps.apple.com/us/app/islacel/id1440373140"
                target="_blank"
                className="me-3" rel="noreferrer"
              >
                <StoreButton as={AppStore} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.islacel.app"
                target="_blank" rel="noreferrer"
              >
                <StoreButton as={GooglePlay} />
              </a>
            </div>
          </div>
        </ReactModal>
      )}
    </>
  );
};

Header.propTypes = {
  disableLogin: PropTypes.bool,
  fixedHeaderBg: PropTypes.bool,
  landingOnly: PropTypes.bool,
  NavigationOptions: PropTypes.any,
};

export default Header;
