/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core';

import { islacelTheme, muiTheme } from '@src/theme';
import Header from './header';
import Footer from './footer';
// import BackToTop from '../backToTopButton';

const Layout = (props) => {
  const {
    children,
    showFooter = true,

    // header props
    headerProps,
  } = props;

  return (
    <>
      <ThemeProvider theme={islacelTheme}>
        <MuiThemeProvider theme={muiTheme}>
          <Header {...headerProps}/>

          <main>{children}</main>

          {showFooter && <Footer />}

          {/* <BackToTop /> */}
        </MuiThemeProvider>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showFooter: PropTypes.bool,
  headerProps: PropTypes.object,
};

export default Layout;
