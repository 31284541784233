import { createTheme } from '@material-ui/core/styles';

export const islacelTheme = {
  colors: {
    white: '#ffffff',
    icGreen: '#0d9953',
    icGray700: '#6c757d',
  },
};

const islacelBlue = '#1c7cd5';
const islacelRed = '#ff4646';
const islacelGray500 = '#8698a8';
const islacelBlack = '#1c2c3a';

export const muiTheme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      '"Poppins"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiFormControl: {
      root: {
        marginBottom: '1rem',
      },
    },
    MuiInput: {
      root: {
        color: islacelBlack,
        fontSize: '1rem',
        fontWeight: '500',
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #ced4da',
          transition:
            'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
        },
        '&:hover:before': {
          borderBottom: `2px solid ${islacelBlue} !important`,
        },
        '&:after': {
          borderBottom: `2px solid ${islacelBlue}`,
          transition:
            'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: islacelGray500,
        fontSize: '0.75rem',
        '&$focused': {
          color: `${islacelBlue}`,
        },
      },
      shrink: {
        transform: 'translate(0, 1.5px) scale(0.92)',
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: islacelRed,
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        minWidth: '280px',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: islacelBlue,
        '&:hover': {
          backgroundColor: islacelBlue,
        },
      },
      current: {
        color: islacelBlue,
      },
    },
  },
});
